.login_div_outside {
  width: 980px;
  margin: 40px auto;
}
.login_title {
  font-size: 23px;
  font-weight: bold;
  color: rgb(192, 0, 0);
  text-align: left;
  margin: 0 15px;
}

/*グレーの枠内*/
.login_menu_div {
  width: 100%;
  height: 600px;
  background: rgb(247, 247, 247);
  margin: 30px 0;
  padding: 35px 25px;
}

.error {
  margin: 10px 20px 0 20px;
  color: #c8030a;
  font-size: 15px;
}

.errorLarge {
  margin-top: 15px;
  padding: 15px 15px 15px;
  color: #c8030a;
  background-color: #fbe5e7;
  text-align: left;
}

.errorNone {
  display: none;
}

.completed {
  margin-top: 15px;
  padding: 15px 15px 15px;
  color: rgb(81, 155, 160);
  background-color: #e5fbeb;
  text-align: left;
}

.title {
  padding-left: 10px;
}
.policy {
  margin-top: 15px;
  padding: 10px 10px 10px 10px;

  background-color: rgb(247, 247, 247);
  text-align: left;
}

.policy_mark {
  margin-top: 10px;
  padding: 10px 10px 10px 10px;

  background-color: rgb(230, 230, 230);
  text-align: left;
}

.login_menu_title {
  text-align: left;
}

.login_menu_table {
  margin: 20px 10px;
  border: solid 1px rgb(191, 191, 191);
  table-layout: fixed;
  border-collapse: collapse;
}

.login_menu_table input[type="text"],
.login_menu_table input[type="password"] {
  border: 1px solid rgb(217, 217, 217);
  margin: 10px 20px;
  width: 598px;
}

.login_menu_table tr {
  height: 60px;
}

.login_menu_table td {
  text-align: left;
  border: solid 1px rgb(191, 191, 191);
  table-layout: fixed;
  border-collapse: collapse;
}

.login_menu_td_left {
  width: 200px;
  background: rgb(230, 230, 230);
}

.login_menu_td_right {
  width: 640px;
  background: rgb(255, 255, 255);
}

.login_menu_td_right label {
  margin: -5px 20px 5px 20px;
}

.login_menu_checkbox {
  margin-top: 30px;
  margin-bottom: 20px;
}
.login_menu_checkbox input[type="checkbox"] {
  height: 23px;
  width: 23px;
  vertical-align: bottom;
  margin-right: 10px;
  cursor: pointer;
}
.login_menu_button {
  margin-top: 30px;
}
.login_div_outside input[type="button"] {
  width: 350px;
  height: 50px;
  padding-bottom: 1px;
  font-size: 20px;
  cursor: pointer;
}

.login_menu_button input[type="button"] {
  background: rgb(192, 0, 0);
  color: white;
  vertical-align: middle;
}

/*横線より下*/
.login_menu_line {
  height: 30px;
  width: 800px;
  margin: 0 10px;
  border-bottom: solid 1px rgb(191, 191, 191);
}

.login_menu_id,
.login_menu_pass {
  margin: 20px 10px;
  text-align: left;
}
.login_menu_id_title,
.login_menu_pass_title {
  margin: 0 5px;
  font-size: 16px;
}
.login_menu_id_svg,
.login_menu_pass_svg {
  width: 19px;
  vertical-align: text-bottom;
}

.login_account {
  margin-top: 30px;
  margin-bottom: 70px;
}

.login_account input[type="button"] {
  vertical-align: middle;
  border: solid 2px black;
  font-weight: bold;
}

/*三角アイコン*/
[class*="c_bulletArrow"]:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 6px;
  content: "";
  vertical-align: baseline;
  border-width: 6px 0 6px 9px;
  border-style: solid;
  border-color: transparent;
}
.login_menu_div .c_bulletArrow_style01:before {
  border-left-color: #288bd3;
}
