.Main_Content {
  /*background-color: red;*/
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px !important;
  margin: 0 auto;
  /*margin-top: 10px;*/
  padding: 0 13px;
  min-height: 700px;
  /*margin-bottom: 63px;*/
  padding-top: 10px;
  padding-bottom: 63px;
}

.content {
  background: rgb(249, 249, 249);
}

/*読み込み中部品*/
.loader_dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  border: none;
}

.loader_modal {
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  opacity: 0.537046;
}
.loader {
  z-index: 10002;
  position: fixed;
  padding: 5px;
  margin: 0px;
  width: 576px;
  height: 88px;
  background-color: rgb(0, 0, 0);
  opacity: 0.447539;
  cursor: default;
  border-radius: 10px;
}
.message {
  padding: 20px 0 10px 0;
  cursor: default;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
}
.img_loading {
  vertical-align: middle;
}
.span_loading {
  position: relative;
  top: 3px;
}
