.area {
  text-align: center;
  width: 800px;
  /*background: red;*/
  text-align: start;
}
.title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.area {
  margin-top: 100px;
  max-width: 1000px;
  margin: auto;
}

.flex {
  display: flex;
}

.cell1 {
  /*background: yellow;*/
  width: 280px;
}
.cell2 {
  /*background: blue;*/
  width: 230px;
}
.cell3 {
  /*background: green;*/
  width: 200px;
}
.cell4 {
  /*background: orange;*/
  width: 150px;
  display: inline-block;
  padding-left: 75px;
  Button {
    margin-right: 5px;
    min-width: 76px;
  }
}

.cellBox {
  margin-left: 10px;
  margin-right: 10px;
}

.closeButoonArea {
  text-align: end;
  margin-top: 20px;
}
.line {
  margin-top: 5px;
  border-top: 1px rgb(68, 114, 196) solid;
}

.row {
  display: flex;
  margin-top: 10px;
}

.remain_left {
  display: inline-block;
  width: 115px;
}
.remain_right {
  display: inline-block;
  width: 60px;
  text-align: right;
}
