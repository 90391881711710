.desc_outside {
  width: 1440px;
  margin: 80px auto 0 auto;
}

/*画面全体で使う部品*/

.desc_demo_button {
  background: linear-gradient(to right, #57a8be, #32639b);
  border-radius: 50px;
  width: 460px;
  height: 76px;
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: 34px;
  margin: 50px auto;
  padding: 11px 0;
}

/*イメージと概要*/

.desc_topImage_outside {
  width: 1200px;
  margin: 80px auto 100px auto;
}

.desc_topImage_title {
  color: #32639b;
  font-size: 40px;
  font-weight: bold;
  margin: 30px 0 50px 0;
}
.desc_topImage_contents {
  height: 460px;
  margin: 0 auto;
}
.desc_topImage_contents td {
  width: 600px;
  position: relative;
}

.desc_topImage_contents td img {
  height: 443px;
}

.desc_topImage_contents_script {
  position: absolute;
  top: 0px;
  left: -50px;
  text-align: left;
  margin: 50px 0;
}

.desc_topImage_contents_title {
  font-size: 26px;
  font-weight: bold;
  color: rgb(71, 94, 138);
}

.desc_topImage_contents_p {
  font-size: 18px;
  margin: 20px 0;
  color: #505050;
}
.desc_topImage_contents_p p {
  margin: 5px 0;
}

/*メニュー(特徴/使い方/ご利用プラン/お客さまの声)*/
.desc_menu {
  margin: 10px 0 0 0px;
  text-align: center;
}
.desc_menu table {
  font-size: 22px;
  margin: 0 auto;
  color: #32639b;
}

.desc_menu td {
  width: 250px;
  height: 50px;
  font-size: 22px;
  font-weight: bold;
  padding: 0 0 4px 0;
  cursor: pointer;
}

.desc_menu_bar {
  border-left: 2px solid rgb(178, 178, 178);
}

/*特徴*/
.desc_feature {
  margin: 120px 40px 0 45px;
}

.desc_feature_title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  color: #32639b;
  margin: 0 0 20px 26px;
}

.desc_feature table {
  margin: 0 auto;
  border-spacing: 20px 20px;
  text-align: left;
}

.desc_feature td {
  width: 520px;
  height: 175px;
  border-radius: 10px;
  background: #f0f3f6;
  padding: 20px 15px 0px 30px;
  vertical-align: top;
}
.desc_feature_contents {
  display: inline-block;
  width: 300px;
}

.desc_feature_image {
  display: inline-block;
  vertical-align: top;
  text-align: right;
  width: 145px;
}

.desc_feature_subTitle {
  font-size: 24px;
  color: rgb(71, 94, 138);
}

.desc_feature_subContents {
  margin: 20px 0;
}

.desc_feature_image_img1 {
  position: relative;
  left: 15px;
  width: 150px;
}

.desc_feature_image_img2 {
  position: relative;
  left: 3px;
  width: 150px;
}
.desc_feature_image_img3 {
  position: relative;
  left: 14px;
  width: 150px;
}
.desc_feature_image_img4 {
  position: relative;
  left: 14px;
  width: 150px;
}

/*使い方*/
.desc_use_outside {
  background: rgb(214, 224, 235);
  margin: 80px auto 100px auto;
  padding: 70px 120px;
}

.desc_use_white {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 45px 68px 55px 72px;
}

.desc_use_title {
  text-align: left;
  font-size: 30px;
  font-weight: bold;
  color: #32639b;
  margin: 0 0 40px 0px;
}

.desc_use_contents {
  background: rgb(240, 243, 246);
  padding: 40px 0 0 30px;
  text-align: left;
}

.desc_use_contents td {
  vertical-align: top;
}

.desc_use_subTitle {
  font-size: 24px;
  color: rgb(71, 94, 138);
  margin: 0 0 10px 0;
}

.desc_use_contents_process {
  margin: 20px 20px 20px 0;
}

.desc_use_content_processs_bold {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.desc_use_content_processs_gray {
  font-size: 15px;
  color: #969696;
}

.desc_use_contents_image {
  width: 505px;
}
.desc_use_contents_image_img1 {
  width: 505px;
  position: relative;
  left: 7px;
  top: 7px;
}
.desc_use_contents_image_img2 {
  width: 505px;
  position: relative;
  left: 23px;
  top: 7px;
}
.desc_use_contents_image_img3 {
  width: 505px;
  position: relative;
  left: 120px;
  top: 7px;
}

.desc_use_contents_image_img4 {
  width: 505px;
  position: relative;
  left: 104px;
  top: 7px;
}

/*通知アイコン*/
.desc_use_contents_icon {
  height: 20px;
  vertical-align: middle;
}

/*三角アイコン*/
[class*="c_bulletDownArrow"]:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 25px auto 21px auto;
  content: "";
  vertical-align: baseline;
  border-width: 30px 30px 0px 30px;
  border-style: solid;
  border-color: transparent;
}
.desc_use_white .c_bulletDownArrow_style01:before {
  border-top-color: rgb(214, 224, 235);
  border-left-color: transparent;
  border-right-color: transparent;
}

/*ご利用プラン*/
.desc_plan {
  margin: 30px 0;
  text-align: left;
  padding: 45px 68px 35px 72px;
}

.desc_plan table {
  margin: 20px auto 20px auto;
  text-align: center;
  border-spacing: 8px 30px;
}
.desc_plan td {
  width: 340px;
}

.desc_plan_tr1 {
  height: 45px;
  font-size: 18px;
}

.desc_plan_tr1 label {
  padding: 0 0 8px 0;
  border-bottom: 3px solid rgb(81, 155, 160);
}

.desc_plan_tr2 {
  height: 70px;
}

.desc_plan_tr2_image {
  margin: 0 20px 0 0;
}

.desc_plan_tr2 img {
  vertical-align: baseline;
  height: 24px;
  margin: 0 5px 0 0px;
}

.desc_plan_tr2 p {
  font-size: 30px;
  color: rgb(112, 112, 112);
  font-weight: bold;
}

.desc_plan_tr3_background {
  background: rgb(238, 247, 248);
  border-radius: 10px;
  margin: 0px 12px;
  padding: 20px 0px 15px 0px;
}

.desc_plan_tr3_div {
  text-align: initial;
  font-size: 18px;
  height: 40px;
}

.desc_plan_tr3_text1 {
  display: inline-block;
  width: 164px;
  height: 32px;
  text-align: right;
}

.desc_plan_tr3_text1_300_1000 {
  width: 156px;
}

.desc_plan_tr3_text1_300 {
  width: 316px;
  text-align: center;
  height: 40px;
  padding-top: 4px;
}

.desc_plan_tr3_text2 {
  color: rgb(112, 112, 112);
  font-size: 26px;
  margin: 0 3px 0 30px;
  vertical-align: baseline;
}
.desc_plan_tr3_text2_300 {
  margin: 0 3px 0 20px;
}

.desc_plan_tr3_text2_1000 {
  margin: 0 3px 0 18px;
}

.desc_plan_tr3_text2_mobius {
  margin-left: 20px;
}

.desc_plan_tr3_text3 {
  height: 50px;
  color: #32639b;
  font-size: 30px;
  font-weight: bold;
  margin: 5px 0 0 0px;
}

.desc_plan_tr3_text3_script {
  font-size: 26px;
  vertical-align: top;
  padding: 2px 0 0 0;
}

.desc_plan_tr3_text3_num {
  font-size: 36px;
}

.desc_plan_remarks {
  position: relative;
  top: -20px;
  font-size: 15px;
  color: #969696;
  /*text-align: right;*/
  margin: 0 25px 0 0;
  text-align: left;
  float: right;
}

.desc_use_outside .desc_demo_button {
  margin: 65px auto 0 auto;
}

/*お客様の声*/

.desc_customer_outside {
  width: 1200px;
  margin: 80px auto 100px auto;
  padding: 0 0 0 72px;
}

.desc_customer_contents {
  padding: 20px 30px;
  text-align: left;
}

.desc_customer_subtitle {
  color: rgb(30, 85, 146);
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 10px 15px;
}

.desc_customer_subcontents {
  display: inline-block;
  height: 180px;
  vertical-align: top;
}

.desc_customer_p1 {
  color: rgb(90, 161, 166);
  font-size: 24px;
  font-weight: bold;
  margin: 15px 0;
}

.desc_customer_image {
  display: inline-block;
  height: 160px;
  padding: 0 60px 0 0;
  margin: 0 35px 0 0;
  border-right: 10px solid rgb(175, 234, 199);
}

.desc_customer_image img {
  height: 100%;
}

.desc_customer_outside .desc_demo_button {
  position: relative;
  left: -35px;
  margin: 40px auto 100px auto;
}

/*関連サービス*/
.desc_related_outside {
  background: rgb(240, 243, 246);
  margin: 80px auto 0 auto;
  padding: 60px 100px;
}

/*トップページに戻る*/
.desc_topreturn_outside {
  margin: 80px auto 0 auto;
  padding: 60px 100px;
}

.desc_related_image_td {
  cursor: pointer;
}
.desc_related_title {
  margin: 0 0 10px 20px;
}

.desc_related_image {
  margin: 0 auto;
}

.desc_related_image table {
  margin: 0 auto;
  border-spacing: 20px 20px;
}

.desc_related_image_white {
  background: rgb(255, 255, 255);
  height: 200px;
}

.desc_related_image td {
  width: 285px;
  text-align: left;
}

.desc_related_image img {
  position: relative;
  height: 170px;
  background: rgb(255, 255, 255);
  cursor: pointer;
}

.desc_related_image_img1 {
  top: 10px;
  left: 15px;
}

.desc_related_image_img2 {
  top: 10px;
  left: 15px;
}

.desc_related_image_img3 {
  top: 10px;
  left: 15px;
}

.desc_related_image_img4 {
  top: 10px;
  left: 15px;
}

.desc_plan_tr3_text3_small {
  font-size: 20px;
}

.desc_plan_tr3_text1_200_1000 {
  width: 200px;
}

.desc_plan_tr3_center {
  text-align: center;
}

.pageTopDiv {
  cursor: pointer;
  margin: auto;
  width: 100px;
}
