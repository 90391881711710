/* animation */

.animation_top {
  opacity: 0;
  visibility: hidden;
  transition: 3s;
  transform: translateY(0px);
}

.animation_right {
  opacity: 0;
  visibility: hidden;
  transition: 1s;
  transform: translateX(300px);
}

.animation_bottom {
  opacity: 0;
  visibility: hidden;
  transition: 1s;
  transform: translateY(20px);
}

.animation_left {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transition-delay: 1s;
  transform: translateX(-20px);
}

.active {
  /* js クラスを追加 */
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.animation_step02 {
  transition-delay: 1.5s;
}

.animation_step03 {
  transition-delay: 2s;
}

.animation_registerButton {
  transition-delay: 2.5s;
}

/* 大枠 */
.frame {
  /* position: absolute; */
  width: 1440px;
  /* background-color: tomato; */
  height: 100%;
  margin: 0 auto;
  padding: 0;
  top: 0;
  bottom: 0;
  background-color: white;
}

.mainVisualFrame {
  width: 100%;
  height: 725px;
  margin-bottom: 80px;
}

/* 
.mainVisual {
	position: relative;
	top: 26px;
	margin: 0 auto;
	width: 1388px;
	height: 560px;
	background-color: aqua;
	border-radius: 16px;
	overflow: hidden;
} */

.mainVisual {
  position: relative;
  top: 0px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: aqua;
  border-radius: 0px;
  overflow: hidden;
}

/* .zoom {
	width: 100%;
	height: 100%;
	overflow: hidden
} */

.zoom-1 img {
  width: 100%;
  height: auto;
  animation: animationZoom1 5s ease-in-out forwards;
}

@keyframes animationZoom1 {
  100% {
    transform: scale(1.03);
  }
}

.overflowText {
  position: absolute;
  font-size: 20px;
  z-index: 15;
  top: 0;
  text-align: center;
  width: 100%;
  /* sheight: 725px; */
  /* background-color: aqua; */
  top: 42%;
  /* transform: translate(-0%, 0%); */
}

.subTitle {
  font-family: "Noto Sans CJK JP", NotoSansCJKjp;
  font-size: 40px;
  margin-bottom: 20px;
}

.pickUp {
  width: 100%;
  margin-bottom: 240px;
  margin-top: 40px;
}

.serviceBG {
  /*background-image: url(img/bgimage.png);*/
  height: 1432px;
  margin-top: 32px;
  background-size: contain;
  margin-bottom: 18px;
}

.analysisCards {
  position: relative;
  padding-top: 100px;
}

.others {
  margin-top: 100px;
}

.registerLoginText {
  text-align: center;
  font-size: 20px;
  margin-bottom: 55px;
}

.registerFrame {
  width: 1107px;
  margin: 0 auto;
  padding: 0 auto;
  height: 208px;
}

.register01 {
  position: relative;
  margin: 0 auto;
  width: 380px;
  height: 208px;
  /* background-color: thistle; */
  float: left;
}

.register02 {
  margin: 0 auto;
  width: 380px;
  height: 208px;
  /* background-color: pink; */
  float: left;
}

.register03 {
  margin: 0 auto;
  width: 347px;
  height: 208px;
  /* background-color: bisque; */
  float: left;
}

.registerButton {
  position: relative;
  margin-top: 90px;
  margin-bottom: 120px;
}

.titleText {
  font-family: Roboto Medium, NotoSansCJKjp, YuGothicM, "メイリオ",
    "ヒラギノ角ゴ Pro W3", "Lucida Grande", "Hiragino Kaku Gothic Pro", Osaka,
    Verdana, "ＭＳ Ｐゴシック", sans-serif;
  font-size: 40px;
  font-style: italic;
  text-align: center;
  margin-bottom: 3px;
}

.titleLine {
  background-color: #000;
  width: 100px;
  height: 6px;
  opacity: 0.3;
  margin: 0 auto;
  border-radius: 3px;
}

.main_imgBox {
  height: 725px;
  overflow: hidden;
  position: relative;
}

.main_img {
  z-index: 10;
  opacity: 0;
  width: 100%;
  height: 725px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: anime 36s 0s infinite;
  animation: anime 36s 0s infinite;
}

.main_img:nth-of-type(2) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.main_img:nth-of-type(3) {
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}

.main_img:nth-of-type(4) {
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
}

.main_img:nth-of-type(5) {
  -webkit-animation-delay: 24s;
  animation-delay: 24s;
}

.main_img:nth-of-type(6) {
  -webkit-animation-delay: 30s;
  animation-delay: 30s;
}

@keyframes anime {
  0% {
    opacity: 0;
  }

  8% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
    transform: scale(1.05);
    z-index: 9;
  }

  100% {
    opacity: 0;
  }
}
