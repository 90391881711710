.InfoDiv {
  text-align: start;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 18px;
  font-weight: bold;
}

.title {
  margin-bottom: 20px;
  margin-top: 30px;
}
.infoArea {
  div {
    margin-top: 20px;
    font-weight: normal;
  }
}

.interval {
  height: 50px;
}

.learningInfoDiv {
}
.flex {
  display: flex;
  flex-wrap: wrap;
}

.periodLabel {
  width: 700px;
  font-weight: normal;
}

.periodButton {
  margin-right: 20px;
  width: 160px;
  text-align: center;
  color: white;
  cursor: pointer;
}

.tableDiv {
  padding-top: 10px;
  /*font-size: 16px;*/
  font-weight: 100;
  font-size: 14px;
  text-align: center;
  th {
    text-align: center;
    padding: 0px;
  }
  td {
    padding: 0px;
    text-align: center;
  }
}
.periodTitle {
  background: rgb(240, 240, 240);
  width: 80px;
  text-align: center;
  margin-right: 5px;
}

.learningInfo {
  margin-bottom: 20px;
  margin-top: 30px;
}

.two {
  width: 40px;
}
