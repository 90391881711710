.UploadDiv {
  text-align: start;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 18px;
  font-weight: bold;
}
.buttonArea {
  margin-top: 50px;
}

.uploadInput {
  display: none;
}
.title {
  margin-bottom: 20px;
  margin-top: 30px;
}

.infoArea {
  div {
    margin-top: 20px;
    font-weight: normal;
  }
}
