.entry_div_outside {
  width: 980px; /*1440px*/
  margin: 30px auto;
}
.entry_title {
  font-size: 22px;
  font-weight: bold;
  color: rgb(192, 0, 0);
  text-align: left;
  margin: 0 15px;
}
.entry_state_table {
  margin-top: 30px;
}

.entry_state_table tr {
  height: 50px;
}

.entry_state_td_state {
  width: 459px;
  font-size: 18px;
  font-weight: bold;
  background: #f0f0f0;
}

.entry_state_td_state_now {
  border: 3px solid rgb(192, 0, 0);
}

/*赤線より下*/
.entry_menu {
  width: 976px; /*1436px*/
  border-top: 3px solid rgb(192, 0, 0);
  margin: 30px 2px;
  padding: 10px 0;
}

.entry_menu_title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0 15px;
}
/*黒線より下*/
.entry_menu_info {
  text-align: left;
  border-top: 1px solid rgb(191, 191, 191);
  margin-top: 15px;
  padding: 15px 0;
}

.entry_menu_info_p1 {
  color: red;
  font-weight: bold;
  text-decoration: underline;
}
.entry_menu_info_p2 {
  margin: 20px 0;
}
.entry_menu_info_p3 {
  color: red;
}

.entry_info {
  margin: 20px 0 10px 0;
}

.entry_move_login {
  text-align: right;
  border-top: 1px solid rgb(191, 191, 191);
}

.entry_menu_pageTop {
  border-top: 1px solid rgb(191, 191, 191);
  text-align: right;
  padding: 10px 0;
}

.entry_menu_pageTop input[type="button"].pageTopButton {
  height: 30px;
  color: #288bd3;
  border: 1px solid rgb(191, 191, 191);
  font-size: 14px;
  padding: 0 10px;
  vertical-align: bottom;
}

.entry_menu table input[type="button"].pageTopButton:disabled {
  opacity: 0.5;
}

/*三角アイコン(1⇒2⇒3状態用)*/
[class*="c_bulletLargeArrow"]:before {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 0;
  height: 0;
  margin: 0 6px;
  content: "";
  vertical-align: baseline;
  border-width: 12px 0 12px 9px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #c00000;
}
.entry_state_table .c_bulletLargeArrow_style01:before {
  border-left-color: #c00000;
}

/*三角アイコン*/
[class*="c_bulletArrow"]:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 6px;
  content: "";
  vertical-align: baseline;
  border-width: 6px 0 6px 9px;
  border-style: solid;
  border-color: transparent;
}
.entry_menu .c_bulletArrow_style01:before {
  border-left-color: #288bd3;
}
