.ModelSettingDiv {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 10px;
}

.ModelSettingCloseDiv {
  text-align: end;
  margin-bottom: 10px;
  background: rgb(191, 191, 191);
  width: 100%;
}

.icon {
  cursor: pointer;
  padding-top: 6.5px;
  padding-right: 5px;
}

.ModelSettingStatusDiv {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.Status {
  color: white;
  background: green;
  width: 500px;
  height: 40px;
  padding-top: 5px;
  margin-right: 100px;
  font-weight: bold;
}
.StatusTitle {
  margin-right: 20px;
  padding-top: 5px;
  font-weight: bold;
}

.ModelSettingInfoDiv {
  display: flex;
  flex-wrap: wrap;
  width: 879px;
}
.pageListDiv {
}

.infoButton {
  color: white;
  font-size: 18px;
  background: gray;
  height: 50px;
  margin-top: 15px;
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
  width: 170px;
  margin-right: 10px;
}

.InfoButtonSingle {
  padding-top: 15px;
}

.infoButtonSelect {
  background: rgb(255, 128, 0);
}

.pageListArea {
  background: rgb(240, 240, 240);
  width: 180px;
  height: 100%;
  box-shadow: -2px 0px 1px lightgrey;
}

.openMenuIcon {
  padding-left: 25px;
}

.listArea {
  /*padding-top: 65px;*/
}

.modelSettingDispDiv {
  /*width: 1019px;*/
  width: 100%;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.listIconDiv {
  padding-top: 5px;
  padding-right: 10px;
}
.listHeader {
  color: black !important;
  background: none !important;
  margin-bottom: 30px;
  line-height: 34.5px !important;
  position: inherit;
}
