.ReportDiv {
  display: flex;
  flex-wrap: wrap;
}
.ReportPage {
  /* background: red;*/
}

.reportListArea {
  /*background: blue;*/
  padding-left: 50px;
}

.reportA_Area {
  /*background: green;*/
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  border-bottom: 1px solid black;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.reportListTitle {
  /*background: orange;*/
  width: 1030px;
  text-align: start;
}
.icon {
  cursor: pointer;
}

.reportAList {
}
.reportListArea2 {
  margin-top: 30px;
}

.reportGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-auto-rows: 200px; */
  /* grid-auto-rows: 50%; */
  column-gap: 20px;
  row-gap: 20px;
  padding-left: 50px;
}

.grid_div {
  /* border: solid 1px gray; */
  /* cursor: pointer; */
  /* height: 100%; */
  background: white;
  height: 210px;
  /* width: 400px; */
}

.reportImg {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.grid_img {
  height: 150px;
  width: 100%;
  text-align: center;
  position: relative;
}
.grid_coment {
  /* height: 10px; */
  height: 30px;
  width: 100%;
  /* font-size: 12px; */
  font-size: 16px;
  padding-left: 10px;
  /*color: gray;*/
  color:black;
}
.grid_title {
  width: 100%;
  font-size: 16px;
  /* height: 35px; */
  /* height: 25px; */
  height: 27px;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 10px;
  /*color: gray;*/
  color:black;
  font-size: 18px;
  display: flex;
  flex-wrap: wrap;
}
.grid_title .caption {
  display: flex;
  width: 7%;
}
.grid_title .caption .settingRange {
  width: 25%;
  text-align: right;
}
.grid_title .caption .setting {
  border: none;
  background: transparent;
}
.grid_title .caption {
  outline: 0;
}
.title .caption .setting svg {
  margin-top: 2px;
  width: 20px;
  height: 20px;
}
.grid_title_text {
  background: white;
  width: 335px;
  padding-left: 7px;
  padding-right: 5px;
}
.grid_title_text:focus {
  outline: solid 2px #1976d2;
  border-radius: 2px;
}
.grid_title_div {
  /*background: red;*/
  width: 335px;
  padding-left: 7px;
  padding-right: 5px;
}
.grid_coment_div {
  padding-left: 7px;
}
.gridObj {
  background: white;
  text-align: start;
}

.display_none {
  display: none;
}
