.reset_div_outside {
  width: 980px;
  margin: 40px auto;
}
.reset_title {
  font-size: 23px;
  font-weight: bold;
  color: rgb(192, 0, 0);
  text-align: left;
  margin: 0 15px;
}

/*グレーの枠内*/
.reset_menu_div {
  width: 100%;
  height: 250px;
  margin: 30px 0 10px 0;
  background: #f5f5f5;
  padding: 35px 25px;
}

.reset_menu_title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 20px 0px;
}

.reset_contents {

  padding: 44px 20px;
  text-align: center;
  margin: 0;
}

.reset_contents_title {
  font-size: 20px;
  font-weight: bold;
}
.reset_contents_p {
  margin: 10px 0 0 0;
}

/*三角アイコン*/
[class*="c_bulletArrow"]:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 6px;
  content: "";
  vertical-align: baseline;
  border-width: 6px 0 6px 9px;
  border-style: solid;
  border-color: transparent;
}

.reset_move_top {
  text-align: right;
}

.reset_move_top .c_bulletArrow_style01:before {
  border-left-color: #288bd3;
}
