
.FaqNav{
  //stickyで固定
  position: -webkit-sticky;//Safari
  position: sticky;
  //固定したい位置
  top: 100px;
  //ナビゲーションの形状
  background:#fff;
  padding: 20px;
}

.subfaq{
  text-align: left;
}
.entry_menu_pageTop {
  border-top: 1px solid rgb(191, 191, 191);
  text-align: right;
  padding: 10px 10px;
}
.notfaq{
  margin-top: 100px;
  text-align: center;
  margin-bottom: 1rem;
}
.pageTopButton {
  height: 30px;
  color: #005e8c;
  border: 1px solid rgb(191, 191, 191);
  font-size: 14px;
  padding: 0 10px;
  vertical-align: bottom;
}

.content_inrr {
  font-size: 16px !important;
  height: 100%;
  max-width: 1440px !important;
  margin: 0 auto;
}

.l_breadcrumb {
}

.AskBtn {
  padding: 10px 0;
  font-size: 1.25rem;
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;

  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  border: 1px solid;
  
  padding-top:  1rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  padding-left: 3rem;
}

.pastchat{
  text-align: center;
  margin-bottom: 100px;
}

.uu{
  background-color: #f6f4f0;
}