.title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
.UserIconDiv {
  padding-top: 30px;
}
.userName {
  text-align: center;
}
.UserIcon {
  background: rgb(192, 192, 192);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  img {
    text-align: center;
    object-fit: cover;
    color: transparent;
    width: 100%;
    height: 100%;
    text-indent: 10000px;
  }
  /*background-image: url("./favicon.ico");*/
}
.test {
  display: none;
  /* background-image: url("./favicon.ico");*/
}

.ProfileArea {
  width: 250px;
  background: white;
  height: 930px;
}

.listArea {
  text-align: start;
  button {
    display: block;
    background: rgb(250, 250, 250);
    height: 45px;
    margin: 20px 20px;
    padding: 0 0 1px 10px;
    cursor: pointer;
    width: 210px;
    text-align: left;
  }
}

.flex {
  display: flex;
}

/**ダイアログエリア*/
.dialogTitle {
  padding-bottom: 20px;
}

.dialogInfoArea {
  padding-left: 20px;
  div {
    margin-bottom: 5px;
  }
}

.dialogInfoDiv {
  /*background: red;*/
  width: 380px;
}

.dialogInterval {
  height: 20px;
}

.dialogUserIcon {
  background: rgb(192, 192, 192);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  img {
    text-align: center;
    /*object-fit: cover;*/
    color: transparent;
    width: 100px;
    height: 100px;
    text-indent: 10000px;
  }
  /*background-image: url("./favicon.ico");*/
}
.dialogtest {
  display: none;
}

.none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.dialogUserIconDiv {
  //padding-right: 20px;
  padding: 0 20px 20px 0;
}

.dialogInfoArea .nicknameDiv {
  margin: 0px 0 20px 0;
}
.nicknameTitle {
  display: inline-block;
}

.nicknameInput {
  display: inline-block;
  width: 200px;
  border: 1px solid #d9d9d9;
}

.nickname {
  outline: none;
}

.company_left {
  display: inline-block;
  width: 80px;
}
.company_right {
  display: inline-block;
  width: 280px;
  vertical-align: top;
}

.name_left,
.address_left {
  display: inline-block;
  width: 65px;
}
.name_right,
.address_right {
  display: inline-block;
  width: 295px;
  vertical-align: middle;
}

.disp_none {
  display: none;
}

.bcg_unset {
  background: unset;
}
