.pro-menu{
  padding-top: 0px !important;
}
.pro-sidebar {
  width: 210px;
  min-width: 210px;
  max-width: 210px;
}
/*
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
  color: white;
  background: rgb(64, 64, 64);
}*/
.subMenuItem .pro-inner-item:hover {
  /*color: black !important;
  background: rgb(255, 230, 153) !important;*/
}
#SlidebarSubMenuItem:hover {
  background: rgb(255, 230, 153);
}
#SlidebarSubMenuItem:hover * {
  color: black;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
  padding: 0px;
}

.pro-inner-list-item .pro-inner-item {
  padding: 0px;
  height: 51px;
  padding-left: 39px !important;
}

.pro-sidebar > .pro-sidebar-inner {
  border-radius: 20px 0px 0px 0px;
}

.ProSidebar {
  padding-left: 0px;
}

.pro-sidebar.collapsed
  .pro-menu
  > ul
  > .pro-menu-item.pro-sub-menu
  > .pro-inner-list-item
  > .popper-inner {
  padding-left: 0px;
}
.pro-sidebar.collapsed {
  width: 35px;
  min-width: 35px;
}

/*
.collapsed .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0px !important;
  padding-top: 20px !important;
}*/

.iconClose > .pro-inner-item {
  padding: 0px !important;
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

#disableMenu {
  cursor: default;
}
#disableMenu > div {
  cursor: default;
  padding-right: 5px;
}
#helpIcon {
  margin-left: 10px;
  cursor: pointer !important;
}
#disableMenu :hover {
  color: rgb(80, 80, 80) !important;
}
#disableMenu :hover * {
  animation: none;
  color: rgb(80, 80, 80) !important;
}
#disableMenu :focus * {
  color: rgb(80, 80, 80);
}
#disableMenu {
  color: rgb(80, 80, 80);
}
/**サブメニュー*/
#SlidebarSubMenuItem_disable {
  /*background: gray;*/
  cursor: default;
}
#SlidebarSubMenuItem_disable > div {
  cursor: default;
  padding-right: 5px;
}
#SlidebarSubMenuItem_disable {
  color: rgb(80, 80, 80);
}
#SlidebarSubMenuItem_disable :hover {
  /*background: gray;*/
  color: rgb(80, 80, 80);
}

#SlidebarSubMenuItem_disable :focus {
  /*background: gray;*/
  color: rgb(80, 80, 80);
}

/*
.pro-inner-item:hover > span,
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  color: white;
}*/
.pro-inner-item:hover > span {
  color: white;
}
/*pro-menu-item pro-sub-menu*/
