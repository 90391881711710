body {
  /* margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  list-style: none;
  font-style: normal;*/
  /* font-family: NotoSansCJKjp, Roboto, YuGothicM, "メイリオ",
    "ヒラギノ角ゴ Pro W3", "Lucida Grande", "Hiragino Kaku Gothic Pro", Osaka,
    Verdana, "ＭＳ Ｐゴシック", sans-serif;*/
  font-family: Roboto, NotoSansCJKjp;
}

/**ノーマル**/
@font-face {
  font-family: "NotoSansCJKjp";
  src: url("../fonts/Noto_Sans_JP/NotoSansJP-Regular.otf") format("opentype");
}
/**ノーマル**/
@font-face {
  font-family: "NotoSansCJKjp";
  src: url("../fonts/Noto_Sans_JP/NotoSansJP-Medium.otf") format("opentype");
  font-weight: 500;
}

/** 太字 */
@font-face {
  font-family: "NotoSansCJKjp";
  src: url("../fonts/Noto_Sans_JP/NotoSansJP-Bold.otf") format("opentype");
  font-weight: 700;
}
/**ちょい細*/
@font-face {
  font-family: "NotoSansCJKjp";
  src: url("../fonts/Noto_Sans_JP/NotoSansJP-Light.otf") format("opentype");
  font-weight: 300;
}
/**極細*/
@font-face {
  font-family: "NotoSansCJKjp";
  src: url("../fonts/Noto_Sans_JP/NotoSansJP-Thin.otf") format("opentype");
  font-weight: 100;
}

/**ノーマル**/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Regular.ttf") format("opentype");
}
/**ノーマル Italic**/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Italic.ttf") format("opentype");
  font-style: italic;
}
/**ちょい太目 Italic**/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-MediumItalic.ttf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
/**ちょい太目**/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Medium.ttf") format("opentype");
  font-weight: 500;
}

/**太時**/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Bold.ttf") format("opentype");
  font-weight: 700;
}
/**ちょいほそ**/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Light.ttf") format("opentype");
  font-weight: 300;
}
/**極細*/
@font-face {
  font-family: "Roboto";
  src: url("../fonts/Roboto/Roboto-Thin.ttf") format("opentype");
  font-weight: 100;
}
