.serviceList * {
  font-family: NotoSansCJKjp;
}
.l_site_header {
  position: absolute;
  min-width: 100%;
  opacity: 0.8;
  background: white;
  z-index: 20;
}
.sote_hed_title_div {
  font-size: 16px;
  color:black;
  padding-top: 15px;
  padding-left: 20px;
  width: 240px;
}
.site_hed_container {
  /*max-width: 1440px !important;*/
  margin: 0 auto;
  padding: 0 13px;
}
.site_hed_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  min-height: 60px;
  max-height: 60px;
  /*background: red;*/
}
.l_site_header ul {
  list-style: none;
  margin-bottom: 25px;
  padding-left: 15px;
}
.site_hed_container {
  max-width: 1440px !important;
  margin: 0 auto;
  padding: 0 13px;
}
.site_hed_ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.site_hed_li_name {
  /* padding: 20px 10px 10px 40px;*/
  padding-top: 16px;
  height: 100%;
  font-size: 14px;
  /* padding-right: 55px;*/
  padding-right: 20px;
  padding-left: 45px;
  width: 193px;
}
.site_hed_li_name svg {
  vertical-align: middle;
  margin-left: 8px;
}
.site_hed_nav__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 630px;
}
.site_hed_nav_wrap {
  margin-left: 20px;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.site_hed_li_name .no_img_label {
  height: 24px;
  vertical-align: middle;
  font-weight: normal;
}
.site_hed_li_name label,
.site_hed_li_name img {
  cursor: pointer;
  font-weight: bolder;
  vertical-align: middle;
  font-size: 16px;
  color: black;
}
.login_label {
  text-align: center;
  /* margin-top: 100px; */
  /* padding-top: 200px; */
  top: 2px;
  position: relative;
  color: rgb(81, 155, 160);
  margin-right: 10px;
}
.login_label2 {
  text-align: center;
  position: relative;
  color: rgb(81, 155, 160);
}
.login_icon {
  vertical-align: middle;
}
/*
.login_div_area {
  padding: 6px 10px 7px 40px;
  padding-top: 12px;
}*/
.login_div_area {
  /*padding: 6px 10px 7px 40px;*/
  /*padding-top: 12px;*/
  margin-top: 12px;
  width: 94px;
  height: 34px;
  /*border: #519ba0 solid 1px;*/
  border: #32639b solid 1px;
  text-align: center;
  cursor: pointer;
  margin-right: 15px;
}
.login_div {
  /*color: #519ba0;*/
  color: #32639b;
  font-weight: normal;
  padding-top: 2px;
  height: 100%;
  /* width: 68px; */
  /*width: 96px;
  height: 34px;*/
  * {
    pointer-events: none;
  }
  svg {
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 10px;
  }
}

.opened {
  background: rgb(213, 233, 239);
}

/*
.site_hed-nav__item:hover > div::after {
  left: 0;
  width: 100%;
  opacity: 1;
  background-color: green;
}*/
/*
.site_hed_li_name:hover {
  .site_hed_li_name:after {
    background-color: black;
  }
}*/
/*
.site_hed_li_name:after {
  content: "";
  left: 25%;
  bottom: 10px;
  display: block;
  height: 2px;
  opacity: 1;
  background-color: red;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}*/

.site_hed_li_name::after {
  content: "";
  left: 25%;
  bottom: 10px;
  display: block;
  height: 2px;
  opacity: 1;
  background-color: #737373;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  /*transition: all 0.2s linear;*/
  transition: all 0.3s;
  transform: scale(0, 1); /*X方向0、Y方向1*/
  transform-origin: center top; /*上部中央基点*/
}
/*
.opened::after {
  left: 0;
  width: 100%;
  opacity: 1;
  background-color: red;
  transition: none;
  transform: scale(1, 1);
  /*transition: all 0.2s linear;*/
/*}

.site_hed_li_name:hover::after {
  transform: scale(1, 1); /*X方向にスケール拡大*/
/*}*/

/** サイトヘッダ　サービス一覧メニュー **/
.site_hed_menu {
  position: absolute;
  left: 0;
  width: 100%;
  /*height: 0;*/
  /*background-color: #f6f6f6;*/
  /*background-color: rgb(228, 228, 228);*/
  border-top: 1px solid #bbb;
  font-size: 1.17em;
  /*-webkit-transition: height 0.25s ease-out;
    -o-transition: height 0.25s ease-out;
    transition: height 0.25s ease-out;*/
  overflow: hidden;
  /*display: none;misawa*/
  transition: 0.5s;
}
.site_hed_menu.opened {
  display: block;
}
.site_hed_menu__opened {
  overflow: visible;
}
.site_hed_menu .site_hed_arr {
  /*display: none;misawa*/
}
.site_hed_menu__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 1440px !important;
  margin: 0 auto;
  justify-content: flex-end;
  background-color: rgb(228, 228, 228);
}
.site_hed_menu__header {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 25%; /*26.53%;*/
  flex: 0 1 25%; /*26.53%;*/
}
.site_hed_menu__header .site_hed_menu__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4.08% 2.04%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #e8e8e8;
  font-size: 1.36em;
}
.site_hed_menu__body div {
  font-size: 14px;
  /* margin: 10px; */
  /* margin-top: 10px; */
  margin-bottom: 20px;
  /*color: rgb(228, 228, 228);*/
  color: rgb(160, 160, 160);
  padding-left: 20px;
}
.site_hed_menu__body {
  /*-webkit-box-flex: 0;
  -ms-flex: 0 1 85%;
  flex: 0 1 85%;*/
  flex: 0 1 340px;
  padding: 20px;
  background: rgb(244, 244, 244);
}
.site_hed_menu__banner {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 25.51%;
  flex: 0 1 25.51%;
  -ms-flex-item-align: center;
  align-self: center;
}
.site_hed_menu__category + .site_hed_menu__category {
  margin-top: 25px;
}
.site_hed_menu__category > a {
  padding-bottom: 10px;
  border-bottom: 1px solid #bbb;
  font-size: 1.36em;
}
.site_hed_menu__subcategory {
  margin-top: 1.43em;
}
.site_hed_menu__subcategory .site_hed_menu__header {
  /* display: none;misawa*/
}
.site_hed_menu_button {
  position: relative;
  margin-right: -4px;
}
.site_hed_menu_button::after,
.site_hed_menu_button::before {
  content: "";
  position: absolute;
  left: calc(50% - 7px);
  display: block;
  width: 15px;
  height: 2px;
  background-color: #333;
  /*-webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.25s;
    transition: -webkit-transform 0.25s;
    -o-transition: transform 0.25s;
    transition: transform 0.25s;
    transition: transform 0.25s, -webkit-transform 0.25s;*/
}
.site_hed_menu_button::before {
  top: 11px;
}
.site_hed_menu_button::after {
  top: 23px;
}
.site_hed_menu_button button {
  display: block;
  width: 48px;
  padding: 30px 0 6px;
  text-align: center;
}
.site_hed_menu_button .site_hed_icon {
  position: absolute;
  top: 7px;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 23px;
  overflow: hidden;
}
.site_hed_menu_button .site_hed_icon::before {
  content: "";
  position: absolute;
  top: 10px;
  left: calc(50% - 7px);
  display: block;
  width: 15px;
  height: 2px;
  background-color: #333;
  /*-webkit-transition: opacity 0.2s;
    -o-transition: opacity 0.2s;
    transition: opacity 0.2s;*/
}
.site_hed_menu_opened .site_hed_menu_button::after,
.site_hed_menu_opened .site_hed_menu_button::before {
  top: 17px;
  left: calc(50% - 9px);
  width: 18px;
}
.site_hed_menu_opened .site_hed_menu_button::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.site_hed_menu_opened .site_hed_menu_button::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.site_hed_menu_opened .site_hed_menu_button .site_hed_icon::before {
  opacity: 0;
}
.site_hed_menu__links {
  /*display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;*/
}

.site_hed_menu__links > li {
  /* width: 25%;
  margin: 2%;
   */
  margin-bottom: 25px;
  display: flex;
}

.site_hed_menu__links > li > a {
  font-size: 20px;
  padding-left: 20px;
  color: black;
}
.site_hed_menu__links_others {
  svg {
    margin-top: 3px;
    /*font-size: 18px;*/
  }
  li > a {
    /*font-size: 16px;*/
    font-size: 20px;
    padding-left: 20px;
    cursor: pointer;
  }
}

li svg {
  /*vertical-align: middle;*/
  margin-top: 3px;
}
.site_hed_menu_centerLine {
  /*38*/
  width: 2px;
  /*background: black;*/
  background-color: rgb(244, 244, 244);
}
.site_hed_menu_Line {
  height: 200px;
  width: 2px;
  background: #e4e4e4;
  margin-top: 25px;
}

/*
  .site-hed-close {
    position: absolute;
    top: 0px;
    right: 20px;
    width: 26px;
    padding-top: 26px;
    overflow: hidden;
  }*/

.site_hed_close {
  position: absolute;
  top: 20px;
  right: 23px;
  width: 26px;
  height: 0;
  padding-top: 26px;
  overflow: hidden;
}
.site_hed_close::after,
.site_hed_close::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 18px);
  display: block;
  width: 36px;
  height: 2px;
  background-color: #333;
  /*-webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: background-color 0.25s ease-out;
    -o-transition: background-color 0.25s ease-out;
    transition: background-color 0.25s ease-out;*/
}
.site_hed_close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.site_hed_close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.site_hed_close:hover::after,
.site_hed_close:hover::before {
  background-color: #c00;
}
@media print, screen and (min-width: 1440px) {
  .site_hed_close {
    right: calc(50% - 692px);
  }
}

.logInOutIconArea {
  vertical-align: middle;
}

.logInOutIconArea {
  padding-top: 18px;
  width: 24px;
}

.icon {
  cursor: pointer;
}

.hide {
  visibility: hidden;
}
