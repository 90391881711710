.area {
  text-align: center;
  width: 100%;
}
.title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}
.area {
  margin-top: 100px;
  max-width: 1000px;
  margin: auto;
}
.logout_loading {
  position: fixed;
  top: 40%;
  left: 50%;
}