.reset_div_outside {
  width: 980px;
  margin: 40px auto;
}
.reset_title {
  font-size: 23px;
  font-weight: bold;
  color: rgb(192, 0, 0);
  text-align: left;
  margin: 0 15px;
}

/*グレーの枠内*/
.reset_menu_div {
  width: 100%;
  height: 410px;
  background: #f7f7f7;
  margin: 30px 0;
  padding: 35px 25px;
}

.reset_menu_title {
  text-align: left;
}

.reset_menu_table {
  margin: 50px 10px 20px 10px;
  border: solid 1px rgb(191, 191, 191);
  table-layout: fixed;
  border-collapse: collapse;
}

.reset_menu_table input[type="text"] {
  border: 1px solid rgb(217, 217, 217);
  margin: 10px 20px;
  width: 598px;
}

.reset_menu_table tr {
  height: 60px;
}

.reset_menu_table td {
  text-align: left;
  border: solid 1px rgb(191, 191, 191);
  table-layout: fixed;
  border-collapse: collapse;
}

.reset_menu_td_left {
  width: 200px;
  background: rgb(230, 230, 230);
}
.reset_menu_td_right {
  width: 640px;
  background: rgb(255, 255, 255);
}

.reset_menu_select {
  width: 598px;
  border: 1px solid #d9d9d9;
  margin: 10px 20px;
  padding: 0 5px;
  -webkit-appearance: auto;
  appearance: auto;
}

.error {
  margin: 10px 20px 0 20px;
  color: #c8030a;
  font-size: 15px;
}

.errorNone {
  display: none;
}

/*確認ボタン*/
.reset_menu_button input[type="button"] {
  background: rgb(192, 0, 0);
  color: white;
  vertical-align: middle;
  width: 350px;
  height: 50px;
  margin-top: 50px;
  padding-bottom: 1px;
  font-size: 20px;
  cursor: pointer;
}
