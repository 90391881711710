.hero_img * {
  font-family: NotoSansCJKjp;
}
.hero_img {
  height: 42vw;
  overflow: hidden;
  position: relative;
  margin: auto;
  img {
    display: block;
    width: 100%;
  }
  div {
    width: 100%;
    margin: auto;
    margin: 0 auto;
    z-index: -1;
    font-size: 1vw;
  }
  b {
    font-size: 1vw;
  }
}
.main_img {
  z-index: 10;
  opacity: 0;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: anime 36s 0s infinite;
  animation: anime 36s 0s infinite;
}

.main_img:nth-of-type(2) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
}

.main_img:nth-of-type(3) {
  -webkit-animation-delay: 12s;
  animation-delay: 12s;
}

.main_img:nth-of-type(4) {
  -webkit-animation-delay: 18s;
  animation-delay: 18s;
}

.main_img:nth-of-type(5) {
  -webkit-animation-delay: 24s;
  animation-delay: 24s;
}

.main_img:nth-of-type(6) {
  -webkit-animation-delay: 30s;
  animation-delay: 30s;
}
@keyframes anime {
  0% {
    opacity: 0;
  }

  8% {
    opacity: 1;
  }

  17% {
    opacity: 1;
  }

  25% {
    opacity: 0;
    /*transform: scale(1.05);*/
    transform: scale(1.1);
    z-index: 9;
  }

  100% {
    opacity: 0;
  }
}

.overflowText {
  position: absolute;
  font-size: 20px;
  z-index: 15 !important;
  top: 0;
  text-align: center;
  width: 100%;
  /* background-color: aqua; */
  top: 42%;
  /* transform: translate(-0%, 0%); */
  color: black;
}
.subTitle {
  font-size: 2vw;
  margin-bottom: 22px;
  color: black;
  padding-top: 36px;
}

.arrowDoenArea {
  position: absolute;
  top: 93%;
  text-align: center;
  z-index: 15 !important;
}

.icon{
  cursor: pointer;
}
