.entry_div_outside {
  width: 980px; /*1440px*/
  margin: 30px auto;
}
.entry_title {
  font-size: 22px;
  font-weight: bold;
  color: rgb(192, 0, 0);
  text-align: left;
  margin: 0 15px;
}
.entry_state_table {
  margin-top: 30px;
}

.entry_state_table tr {
  height: 50px;
}

.entry_state_td_state {
  width: 459px;
  font-size: 18px;
  font-weight: bold;
  background: #f0f0f0;
}

.entry_state_td_state_now {
  border: 3px solid rgb(192, 0, 0);
}

.entry_state_td_img {
  position: relative;
  top: 2px;
  width: 50px;
  margin: 0 -12px;
}

.error {
  width: 609px;
  margin: 10px 20px 0 20px;
  padding: 4px 7px 3px;
  color: #c8030a;
  background-color: #fbe5e7;
}
.errorLarge {
  margin-bottom: 15px;
  padding: 15px 15px 15px;
  color: #c8030a;
  background-color: #fbe5e7;
}

.errorNone {
  display: none;
}

/*赤線より下*/
.entry_menu {
  width: 976px; /*1436px*/
  border-top: 3px solid rgb(192, 0, 0);
  margin: 30px 2px;
  padding: 10px 0;
}

.entry_menu_title {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 0 15px;
}
/*黒線より下*/
.entry_menu_info {
  text-align: left;
  border-top: 1px solid rgb(191, 191, 191);
  margin-top: 15px;
  padding: 15px 0;
}
.entry_menu_info ul {
  list-style-position: inside;
  margin: 25px 0 0 0;
  padding: 0 14px;
}
.supportSvg {
  width: 20px;
  margin: 0 5px 0 0px;
  vertical-align: text-bottom;
}
.entry_menu_info_brackets {
  margin: 0 32px;
  margin-bottom: 15px;
}
/*入力フォーム*/
.entry_menu table {
  width: 100%;
  border: solid 1px rgb(191, 191, 191);
  table-layout: fixed;
  border-collapse: collapse;
  margin: 20px 0px;
}
.entry_menu table tr {
  height: 40px;
  text-align: left;
}

.entry_menu .entry_menu_tr_2line {
  height: 66px;
}
.entry_menu .entry_menu_tr_3line {
  height: 92px;
}

.entry_menu table td {
  border: solid 1px rgb(191, 191, 191);
}

.entry_menu_required {
  position: absolute;
  height: 20px;
  background: #c00000;
  color: white;
  font-size: 14px;
  margin: 2px 0;
  padding: 0px 5px;
  right: 10px;
}

.entry_menu_required_1 {
  position: absolute;
  height: 20px;
  background: #c00000;
  color: white;
  font-size: 14px;
  margin: 0 0;
  padding: 0px 5px;
  right: 10px;
  top: 25px;
}

.entry_menu .inputRequired {
  background: rgb(255, 255, 255);

}


.entry_menu_td_left {
  position: relative;
  width: 250px;
  background: rgb(230, 230, 230);
  margin: 0 10px;
}

.entry_menu_td_center {
  position: relative;
  width: 975px;
  background: rgb(230, 230, 230);
  margin: 0 20px;
}

.entry_menu_td_right {
  margin: 0 10px;
}

.entry_menu_td_right p {
  margin: 5px 20px 5px 20px;
}

.entry_menu .inputRequiredfull {
  background: rgb(255, 255, 255);
  width:800px;
  display: inline-block;
}

.fileinput{
  margin: 10px 20px;
  padding: 0 5px;
  
}
.fileinput input[type="file"]{
  width: 795px;
  border: 1px solid rgb(217, 217, 217);
}

.entry_menu table input[type="text"] {
  width: 609px;
  border: 1px solid rgb(217, 217, 217);
  margin: 10px 20px;
  padding: 0 5px;
}

.entry_menu table textarea {
  width: 800px;
  
  border: 1px solid rgb(217, 217, 217);
  margin: 10px 20px;
  padding: 0 5px;
}

.entry_menu table input[type="text"].inputRequiredfull {
  width: 800px;
  border: 1px solid rgb(217, 217, 217);
  display: inline-block;
  padding: 0 5px;
}

.entry_menu table select {
  width: 609px;
  border: 1px solid rgb(217, 217, 217);
  margin: 10px 20px;
  padding: 0 5px;
  appearance: auto;
}

.entry_menu table input[type="text"].inputAddress {
  width: 150px;
}

.entry_menu table input[type="button"].addressButton {
  height: 21px;
  background: rgb(230, 230, 230);
  border: 1px solid rgb(191, 191, 191);
  font-size: 13px;
  padding: 0 10px;
  vertical-align: bottom;
  margin: 10px 0;
}

.entry_menu table input[type="button"].addressButton:disabled {
  opacity: 0.5;
}

.entry_menu_select_purpose {
  margin: 2px 5px 8px 30px;
}
.entry_menu table .entry_menu_select_purpose input[type="text"] {
  width: 482px;
  margin: 0 5px;
}

.entry_menu_select_service {
  padding: 0 20px;
}
.entry_menu_select_service div {
  display: inline-block;
  margin: 0 25px 0 0;
}

.entry_menu_privacy_svg {
  width: 19px;
  vertical-align: text-bottom;
}
.entry_menu input[type="checkbox"] {
  height: 16px;
  width: 16px;
  margin-right: 10px;
  margin-top: 1px;
  vertical-align: text-top;
  cursor: pointer;
}

.entry_menu_privacy .consent {
  margin: 8px 25px 5px 20px;
}

.entry_menu_term {
  height: 450px;
}

.entry_menu_term p {
  margin: 20px 20px;
}

.entry_menu_term_bold {
  font-weight: bold;
}

.entry_menu_term p ul {
  margin: -4px 0px 0px 23px;
}

.entry_menu_term p ul li {
  margin: 0px 0px 8px 0px;
}

.entry_menu_term img {
  width: 15px;
  height: 14px;
}

.entry_menu_term_td .entry_menu_term_right1 {
  text-align: right;
  margin: 20px 20px 0 20px;
}
.entry_menu_term_td .entry_menu_term_right2 {
  text-align: right;
  margin: 0 20px 20px 20px;
}

.entry_menu_agree {
  width: 976px;
  background: rgb(230, 230, 230);
  margin: 25px 0;
  padding: 20px;
}
.entry_menu_agree_red {
  color: rgb(204, 0, 0);
  font-weight: bold;
  margin: 0 10px 0 0;
}

.entry_menu_confirm {
  height: 90px;
  width: 976px;
  background: rgb(255, 248, 242);
  margin: 30px 0;
}

.entry_menu_confirm button.confirmButton {
  position: relative;
  top: 20px;
  height: 50px;
  width: 250px;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 4px;
  font-size: 20px;
  padding: 0 10px;
  cursor: pointer;
}

.entry_menu_confirm button.confirmButtonEnabled {
  background: #cc0000;
  border: 1px solid #cc0000;
  color: #fff;
}

.entry_menu_confirm button.confirmButton:disabled {
  opacity: 0.5;
}

.confirmButtonSvg {
  width: 24px;
  margin: 0 5px;
  vertical-align: text-bottom;
}

.entry_menu_pageTop {
  border-top: 1px solid rgb(191, 191, 191);
  text-align: right;
  padding: 10px 0;
}

.entry_menu_pageTop input[type="button"].pageTopButton {
  height: 30px;
  color: #005e8c;
  border: 1px solid rgb(191, 191, 191);
  font-size: 14px;
  padding: 0 10px;
  vertical-align: bottom;
}

.entry_menu table input[type="button"].pageTopButton:disabled {
  opacity: 0.5;
}
.entry_menu_ssl {
  height: 50px;
  width: 976px;
  background: #f2f2f2;
  margin: 20px 0 0 0;
  padding: 15px 0;
}

/*三角アイコン(1⇒2⇒3状態用)*/
[class*="c_bulletLargeArrow"]:before {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 0;
  height: 0;
  margin: 0 6px;
  content: "";
  vertical-align: baseline;
  border-width: 12px 0 12px 9px;
  border-style: solid;
  border-color: transparent;
}
.entry_state_table .c_bulletLargeArrow_style01:before {
  border-left-color: #c00000;
}

/*三角アイコン(aタグ用)*/
[class*="c_bulletArrow"]:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 6px;
  content: "";
  vertical-align: baseline;
  border-width: 6px 0 6px 9px;
  border-style: solid;
  border-color: transparent;
}
.entry_menu_privacy .c_bulletArrow_style01:before {
  border-left-color: #c00000;
}

.pointer_events_none {
  pointer-events: none;
  opacity: 0.5;
}

textarea {
  resize: none;
}