.outside {
  background: #f9f9f9;
}
.src_content {
  width: 1440px;
  margin: auto;
  background: #f9f9f9;
}
.src_Main_Content {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px !important;
  margin: 0 auto;
  padding: 10px 13px 63px 13px;
  min-height: 700px;
}

.App_App {
  width: 100%;
}

.App_AppArea {
  margin: auto;
}

.App_flex {
  display: flex;
}

.title {
  display: inline-block;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.link {
  float: right;
  text-align: end;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    cursor: pointer;
    color: blue;
  }
}

.hidden {
  visibility: hidden;
}
