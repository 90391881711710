.UserInfo {
  div {
    color: white;
  }
  padding-top: 20px;
}
.UserInfo_Area {
  /*background: red;*/
  height: 240px;
  width: 200px;
  margin: 5px;
  /*display: contents;*/
}

.UserIcon_Area {
  /*background: blue;*/
  background: rgb(64, 64, 64);
  height: 120px;
}
.UserText_Area {
  /*background: green;*/
  background: rgb(64, 64, 64);
  height: 120px;
}

.UserText_Area_ul {
  padding-left: 10px !important;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 13px;
  }
}
.UserIconDiv {
  padding-top: 10px;
}

.UserIcon {
  /*background: yellow;*/
  background: rgb(192, 192, 192);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
  width: 68px;
  height: 68px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  img {
    text-align: center;
    object-fit: cover;
    color: transparent;
    width: 100%;
    height: 100%;
    text-indent: 10000px;
  }
  /*background-image: url("./favicon.ico");*/
}

.UserName {
  text-align: center;
}

.test {
  display: none;
  background-image: url("./favicon.ico");
}

.createIcon {
  position: absolute;
  left: 175px;
  cursor: pointer;
}

.none {
  * {
    transition-property: none;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
  li {
    display: none;
  }
  .UserName {
    display: none;
  }
  .UserInfo_Area {
    width: 75px;
  }
  .UserIconDiv {
    padding-top: 75px;
    width: 75px;
  }
}

.UserIconClose {
  width: 25px;
  height: 25px;
  margin: 0px;
}

.closeArea {
  .UserIcon_Area {
    width: 25px;
  }
  .UserText_Area {
    width: 25px;
  }
}

/*サイドメニュー折り畳み時のアイコン位置*/
.UserIconDivClose {
  padding-top: 106.5px !important;
}

.UserText_Area_div {
  padding-top: 8px;
  padding-left: 10px;
}
