.ContentHeder {
  height: 70px;
  width: 1215px;
}
.flexArea {
  display: flex;
  flex-wrap: wrap;
}

.serchIcon {
  cursor: pointer;
}

.icon {
  cursor: pointer;
}

.iconArea {
  padding-top: 6.5px;
  padding-left: 5px;
  padding-right: 5px;
}

.iconArea_left {
  padding-left: 10px;
}

.none {
  /*width: 180px;*/
  width: 310px;
  .serchInput {
    display: none;
  }
}

.show {
  display: none;
}
.rightIcon {
  padding-left: 135px;
}
.badge {
  position: absolute;
  margin-left: 0px;
  margin-top: -33px;
  font-size: 12px;
  line-height: 15px;
  background-color: rgb(200,20,50);
  border: 2px solid rgb(200,20,50);
  border-radius: 100%;
  color: white;
  text-align: center;
  width: 18px;
  height: 18px;
}
.noticepopper {
  position: relative;
  display: inline-block;
  width: 450px;
  margin: 8px 0;
  left: -120px;
  min-width: 120px;
  max-width: 100%;
  color: #eee;
  background: #fff;
  border: solid 2px #eee;
  box-sizing: border-box;
}
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  .tbody {
    background-color: #eee;
  }
  .tr {
    &:nth-child(2n) {
      background-color: white;
    }
  }
  .td {
    color: black;
    width: 100%;
    &:nth-child(1) {
      width: 130px;
    }
    &:nth-child(2) {
      width: calc(100% - 35px);
      padding-left: 2px;
    }
    .td_title {
      padding-left: 7px;
    }
    .td_message {
      font-size: 14px;
      padding-left: 7px;
    }
    .td_date {
      padding-top: 2px;
      padding-left: 7px;
    }
  }
}
