.gridjs-wrapper {
  box-shadow: none;
  border-radius: unset;
}

.gridjs-tbody {
  background-color: transparent;
}
td.gridjs-td {
  background-color: transparent;
}

th.gridjs-th {
  border: none;
}
td.gridjs-td {
  border-right: none;
  border-left: none;
}

th.gridjs-th:last-child button {
  display: none;
}
th.gridjs-th:last-child {
  pointer-events: none;
}

