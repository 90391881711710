.service_area {
  margin-bottom: 30px;
  /* margin-top: 252px; */
  /* margin-top: 200px;
  /*margin-top: 200px;*/
  background-image: url("./img/service/背景パターン.svg");
  background-size: 1440px;
  background-repeat: repeat;
  height: 850px;
  &::before {
    /*content: "";
    position: absolute;
    z-index: -1;
    width: 1440px;*/
    content: ""; /* 疑似要素に必須 */
    width: 100%; /* 幅いっぱい */
    height: 100%; /* 高さいっぱい */
    display: block; /* 高さを指定するためにブロック要素にする */
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0,
      #fff 100%
    ); /* 徐々に透明にする */
    position: absolute; /*  */
    top: 0;
    left: 0;
  }
}

.service_grid_item_a {
}

.iconSVG {
  width: 350px;
  height: 225px;
}

.poclabImg {
  width: 350px;
  height: 160px;
}

.ttl_title {
  font-size: 40px;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-style: italic;
  /* font-size: 38px; */
  font-weight: 500;
}
.ttl_underLine {
  width: 100px;
  height: 6px;
  background: rgb(179, 179, 179);
  border-radius: 10px;
  margin: auto;
  margin-bottom: 30px;
}
.service_div {
  width: 1440px;
}

.service_div_area_title,
.others_div_area_title {
  font-weight: 500;
  padding: 50px 0px 20px 0px;
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 125px;
  font-size: 32px;
  color: black;
}

.service_div_area,
.others_div_area {
  padding: 0px 40px 0px 40px;
  padding-top: 75px;
}

.service_grid {
  margin-top: 60px;
  margin-bottom: 40px;
  display: grid;
  /*grid-template-columns: 1fr 1fr 1fr;*/
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 27px;
  padding-left: 120px;
  padding-right: 120px;
}

.service_grid_item_num {
  color: #7ad4cb;
  font-size: 40px;
  font-weight: bold;
  font-family: "NotoSansCJKjp";
  padding-top: 7px;
}

.service_grid_item_title {
  padding-left: 40px;
  font-weight: bold;
  color: black;
  padding-top: 25px;
  font-size: 22px;
}

.service_grid a {
  color: rgb(56, 115, 168);
  cursor: pointer;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
}

.service_grid_item {
  /*background-color: orange; */
  background: white;
  max-width: 350px;
  min-width: 350px;
  cursor: pointer;
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* border: solid 2px gray;
}

.service_grid_item_div {
  /* background: blue;*/
  box-shadow: 0 0 8px #a0a0a0;
}

.service_grid_item_div_img_div {
  text-align: center;
  /*width: 352px;
  height: 279px;*/
  height: 225px;
  div > div > img {
    cursor: pointer;
  }
}
.service__item {
  margin-top: 275px;
  width: 1440px;
}
.service_grid_item_top {
  min-width: 352px;
  max-width: 352px;
  max-height: 198px;
  min-height: 192px;
  margin: auto;
}

.service_grid_item_top {
  font-family: "NotoSansCJKjp";
  min-width: 350px;
  max-width: 350px;
  max-height: 50px;
  min-height: 70px;
  margin: auto;
  padding-left: 27px;
  display: flex;
}
.service_grid_item_text {
  white-space: break-spaces; //文字列改行
  min-width: 350px;
  max-width: 350px;
  max-height: 80px;
  min-height: 80px;
  margin: auto;
  /*margin-top: 20px;*/
  font-size: 20px;
  padding-left: 30px;
  color: #505050;
  padding-right: 10px;
  /*text-align: center;*/
}
.service_grid_item_link {
  min-width: 350px;
  max-width: 350px;
  max-height: 45px;
  min-height: 45px;
  margin: auto;
  text-align: center;
}
.service_div_area_text {
  font-size: 16px;
  /* padding-top: 100px; */
  padding-left: 125px;
  /* font-size: 30px; */
  color: black;
  /* font-weight: 100; */
  padding-right: 125px;
  font-weight: 100;
}
/**その他*/
.others_grid {
  /*margin-top: 50px;*/
  margin-bottom: 20px;
  display: grid;
  /*grid-template-columns: 1fr 1fr 1fr;*/
  grid-template-columns: 1fr 1fr;
  /* grid-gap: 85px;*/
  /*grid-gap: 50px;*/
  padding-left: 110px;
  padding-right: 110px;
}
@media screen and(max-width:900px) {
  .others_grid {
    /*margin-top: 50px;*/
    margin-bottom: 20px;
    display: grid;
    /*grid-template-columns: 1fr 1fr 1fr;*/
    grid-template-columns: 1fr;
    /* grid-gap: 85px;*/
    grid-gap: 30px;
  }
}
.others_grid_item {
  background: white;
  max-height: 195px;
  min-height: 195px;
  max-width: 545px;
  min-width: 545px;
  margin-left: auto;
  margin-right: auto;
  /*border: solid 2px gray;*/
  /*background: red;*/
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 0 8px #a0a0a0;
}

/** その他*/
.others_grid_item img {
  margin: 5px 25px 5px 25px;
  max-height: 160px;
  min-height: 160px;
  max-width: 160px;
  min-width: 160px;
  margin-left: 10px;
  margin-top: 0px;
}

.others_grid_img_div {
  max-width: 150px;
  min-width: 150px;
  max-height: 110px;
  min-height: 110px;
  /*background: green;*/
}

.others_grid_item_title {
  padding-right: 25px;
  font-weight: bold;
  padding-left: 35px;
  padding-top: 20px;
  font-size: 24px;
}
.others_grid_item_text {
  padding-top: 25px;
  padding-left: 35px;
  padding-right: 10px;
  font-size: 20px;
  padding-top: 20px;
  color: #505050;
}

.others_grid_item table {
  /*padding-top: 30px;*/
  padding-top: 15px;
}

.others_title {
  font-size: 36px;
  padding-bottom: 38px;
}

.service_hashTag_div {
  padding-top: 20px;
  text-align: start;
  padding-left: 20px;
}

.genbaTitle {
  padding-left: 0px;
}
