.register_area {
  /*padding-bottom: 125px;*/
  padding-bottom: 250px;
  /*margin-top: 255px;*/
  width: 1440px;
}
.ttl_title {
  font-size: 40px;
  font-style: italic;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-style: italic;
  /* font-size: 38px; */
  font-weight: 500;
  /*text-decoration: underline;
  text-decoration-color: gray;
  text-decoration-thickness: 5px;*/
}
.ttl_underLine {
  width: 100px;
  height: 6px;
  background: rgb(179, 179, 179);
  border-radius: 10px;
  margin: auto;
  margin-bottom: 20px;
}

.register_div_area_title {
  text-align: center;
  color: black;
  font-size: 20px;
  a {
    color: #3873a8;
    cursor: pointer;
    text-decoration: underline;
  }
}

.register {
  margin: 0 -5em;
}
.register__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  /*margin-top: 5em;*/
  margin-top: 90px;
  list-style: none;
}
.register__item {
  padding: 0 3.75em 0 1.25em;
}
.register__item:not(:last-of-type) {
  position: relative;
}
.register__item:not(:last-of-type)::after {
  content: "";
  width: 6.25em;
  height: 6.25em;
  display: block;
  border: solid #c6c6c6;
  border-width: 2px 2px 0 0;
  -webkit-transform: rotate(60deg) skew(40deg);
  -ms-transform: rotate(60deg) skew(40deg);
  transform: rotate(60deg) skew(40deg);
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
}
.register__def_ttl {
  /*font-size: 2.5em; */
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  color: #828282;
}
.register__def_data {
  text-align: center;
  /*margin-top: 2.44444em;*/
  margin-top: 35px;
  /*font-size: 1.125em;*/
  font-size: 18px;
}
.register__def_data a {
  text-decoration: underline;
  cursor: pointer;
}
.register__txt {
  font-size: 0.75em;
  text-align: center;
  margin-top: 1.25em;
}
.register__txt a {
  color: #cc0000;
}
/*
@media screen and (max-width: 1440px) {
  .register {
    margin: 0;
  }
  .register__list {
    margin-top: 5.55556vw;
  }
  .register__item {
    padding: 0 4.16667vw 0 1.38889vw;
  }
  .register__item:not(:last-of-type)::after {
    width: 6.94444vw;
    height: 6.94444vw;
  }
  .register__def_ttl {
    font-size: 2.5em;
  }
  .register__def_data {
    margin-top: 3.05556vw;
    font-size: 1.125em;
  }
  .register__txt {
    font-size: 0.75em;
    margin-top: 1.04167vw;
  }
}*/
/*
@media screen and (max-width: 767.98px) {
  .register__list {
    display: block;
    margin: 0;
  }
  .register__item {
    padding: 0;
  }
  .register__item:not(:last-of-type) {
    padding-bottom: 4.0625em;
  }
  .register__item:not(:last-of-type)::after {
    width: 2.1875em;
    height: 2.1875em;
    border: solid #c6c6c6;
    border-width: 2px 2px 0 0;
    -webkit-transform: rotate(145deg) skew(20deg);
    -ms-transform: rotate(145deg) skew(20deg);
    transform: rotate(145deg) skew(20deg);
    right: 0;
    left: 0;
    top: auto;
    bottom: 1.25em;
    margin: auto;
  }
  .register__def_ttl {
    font-size: 1.5em;
  }
  .register__def_data {
    font-size: 1em;
    margin-top: 0.71875em;
  }
  .register__txt {
    font-size: 0.75em;
    margin-top: 0.46875em;
  }
}*/

/* c-anim-fadeL
----------------------- */
.c_anim_fadeL,
.c_anim_fadeL1,
.c_anim_fadeL2,
.c_anim_fadeL3,
.c_anim_fadeL4 {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
  -webkit-transition: opacity 0.4s ease-out, -webkit-transform 0.4s ease-out;
  transition: opacity 0.4s ease-out, -webkit-transform 0.4s ease-out;
  -o-transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  transition: opacity 0.4s ease-out, transform 0.4s ease-out;
  transition: opacity 0.4s ease-out, transform 0.4s ease-out,
    -webkit-transform 0.4s ease-out;
}
.c_anim_fadeL.c_anim_play,
.c_anim_play.c_anim_fadeL1,
.c_anim_play.c_anim_fadeL2,
.c_anim_play.c_anim_fadeL3,
.c_anim_play.c_anim_fadeL4 {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.c_anim_fadeL1 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.c_anim_fadeL2 {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

/**新規登録用ボタン**/
.entry_button {
  background: linear-gradient(to right, rgb(87, 168, 190), rgb(50, 99, 155));
  width: 500px;
  height: 76px;
  border-radius: 50px;
  color: white;
  text-align: center;
  padding-top: 10px;
  font-size: 24px;
  label {
    font-size: 32px;
    pointer-events: none;
  }
  cursor: pointer;
}

.entry_div {
  vertical-align: middle;
  width: 500px;
  height: 76px;
  margin: auto;
  margin-top: 148px;
}

.register_div_area_title_bot {
  text-align: center;
  color: black;
  font-size: 20px;
  padding-top: 30px;
  a {
    color: #3873a8;
    cursor: pointer;
    text-decoration: underline;
  }
}


.eventNone{
  pointer-events: none;
  color: gray;
}
.eventNoneButton{
  pointer-events: none;
  background: #9c9c9c;
  color: gray;
}

.register_div{
  width: 1440px;
}
