.AnalysisDiv {
  /*background: red;*/
}

.SetteingDiv {
  /*background: blue;*/
  /*margin-left: 50px;
  margin-right: 50px;*/
  margin: auto;
  width: 1000px;
}
.SettingTitleDiv {
}

.ErrorDiv {
  min-height: 100px;
  /*background: orange;*/
  padding-left: 50px;
  padding-bottom: 50px;
}

.SettingTitleDiv {
  /*background: green;*/
  border-top: solid 1px gray;
  padding-top: 45px;
}

.SettingTitle {
  text-align: center;
  font-size: 22px;
  padding-top: 15px;
  height: 70px;
}

.interval {
  height: 50px;
}
.interval_before {
  height: 50px;
  /*background: gold;*/
}
.interval_after {
  height: 130px;
  /*background: silver;*/
}

.inputTitleArea {
  text-align: start;
}

.link {
  cursor: pointer;
}

.dropZone {
  height: 96px;
  background: white;
  vertical-align: middle;
  border: 2px dashed #959595;
  text-align: center;
}
.dropZoneActive {
  border: 2px solid rgb(35, 176, 231);
  //background: rgba(35, 176, 231, 0.6);
  background: rgb(123, 208, 241);
}

.dropZoneComment {
  padding-top: 36px;
}

.tableDiv {
  width: 1000px;
  margin-top: 10px;
  th,
  td {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
  th {
    color: white;
    background: rgb(67, 67, 101);
    /*border-left: 1px solid white !important;
    border-right: 1px solid white !important;*/
  }
}
.icon {
  cursor: pointer;
}

.lerningCsvArea {
  text-align: start;
}

.required_icon {
  display: inline-block;
  color: #fff;
  background: #cc3232;
  padding: 1px 5px 1px 5px;
  font-size: 12px;
  border-radius: 5px;
  margin-left: 5px;
  padding-top: 3px;
}

.lerningCsvTileDiv {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.pageTopDiv {
  cursor: pointer;
  margin: auto;
  width: 100px;
}

.addButonArea {
  padding-top: 50px;
}
.addButon {
  height: 50px;
  width: 210px;
  border: solid 1px black;
  border-radius: 10px;
}

.StartMoveDiv {
  position: fixed;
  right: 10px;
  top: 350px;
  display: flex;
  flex-wrap: wrap;
  height: 70px;
  vertical-align: middle;
  box-shadow: 1px 1px 2px;
  cursor: pointer;
}

.StartMoveDivIcon {
  background: rgb(0, 32, 96);
  width: 70px;
}
.StartMoveIcon {
}
.StartMoveText {
  background: white;
  width: 200px;
  text-align: center;
  padding-top: 15px;
  font-size: 30px;
}

.tableDiv table {
  border-collapse: unset;
  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  td {
    border: 1px solid gray !important;
  }
}

.sideButton {
  color: white;
  font-size: 12px;
  top: -8px;
  position: relative;
}

.maxSize {
  width: 100%;
  height: 100%;
}
