.LearningDiv {
  text-align: start;
  padding-left: 20px;
  padding-top: 15px;
  font-size: 18px;
  font-weight: bold;
}

.title {
  margin-bottom: 20px;
  margin-top: 30px;
}

.tableAddArea {
  display: flex;
  flex-wrap: wrap;
}
.Button {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.periodButtonArea {
  padding-top: 5px;
  width: 680px;
}

.date {
  input {
    padding-right: 0px;
  }
  span {
    display: none !important;
  }
}

.time {
  input {
    padding-right: 0px;
  }
  span {
    display: none !important;
  }
}
.tbody {
  margin-top: 10px;
  height: 50px;
  overflow-y: scroll;
  width: 840px;
  display: block;
  border: 1px solid #e5e7eb;
}
.interval {
  height: 50px;
  width: auto;
}
.startDateArea {
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}

.radioArea {
  display: flex;
  flex-wrap: wrap;
}

.startDateTitle {
  width: 100px;
  padding-top: 5px;
}

.startDateArea {
}
.startText {
  padding-top: 5px;
  padding-left: 5px;
}

.buttonArea {
}

.intervalButton {
  height: 20px;
}

.StartButton {
  padding-left: 635px;
}
