.SlideBar {
  /*height: 750px;*/
  min-height: 763px;
  width: 210px;
}

.userInfo {
  /*height: 20px;*/
}

.menuTitle {
  div {
    padding: 0px !important;
    pointer-events: none;
  }
}

.subMenuItem:hover {
  color: black;
  background: rgb(255, 230, 153);
}
.subMenuItem {
  /* background: red;*/
}

.barClose {
  /*width: 80px;*/
  width: 35px;
}

.flex {
  display: flex;
}

.selectedMenu {
  background: rgb(64, 64, 64);
}

.selectedMenu > div:first-child {
  color: white;
  font-weight: bold;
}

.moveMenu {
  position: fixed;
}

.disabled_color {
  color: rgb(80, 80, 80);
}
