/*.content_inrr {
  font-size: 16px !important;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.25em;
}*/
/*
.content_inrr {
  font-size: 16px !important;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1440px !important;
  margin: 0 auto;
  /* margin-top: 2%; */
// padding: 0 13px;
/* min-height: 700px; */
/* margin-bottom: 63px;*/
// background: yellow;
//}

.content_inrr {
  font-size: 16px !important;
  height: 100%;
  /* display: flex; */
  /* flex-wrap: wrap; */
  max-width: 1440px !important;
  margin: 0 auto;
  /* margin-top: 2%; */
  /* padding: 0 13px; */
  /* min-height: 700px; */
  /* margin-bottom: 63px; */
  /*background: yellow;*/
}

.l_breadcrumb {
  /*border-bottom: solid 1px #a1a1a1;
  height: 46px;*/
}
