.ModelPage {
  /*background: red;*/
}

.tableArea {
  td {
    color: gray;
  }
  a {
    color: blue;
    cursor: pointer;
  }
}

.moreIcon {
  cursor: pointer;
}

.tableArea {
  * {
    text-align: start;
  }
  th {
    color: gray;
    border-bottom: 1px solid #ccc;
    text-align: center;
    max-height: 20px;
  }
}

.addButonArea {
  padding-top: 100px;
}

.addButon {
  height: 50px;
  width: 210px;
  border: solid 1px black;
  border-radius: 10px;
  /*box-shadow: 1px 1px 4px;*/
}

.none {
  .addButon {
    display: none;
  }
  table th {
    display: none;
  }
  .stateDiv {
    display: none;
  }
  table {
    td:nth-of-type(1) {
      /*padding-right: 0px;*/
    }
    td:nth-of-type(2) {
      display: none;
    }
    td:nth-of-type(3) {
      display: none;
    }
    td:nth-of-type(4) {
      //display: none;
      padding: 0px;
    }
    td:nth-of-type(5) {
      display: none;
    }
    td:nth-of-type(6) {
      display: none;
    }

    /*    width: 1150px;
    padding-left: 50px;*/
  }
  .tableDiv {
    padding-left: 0px !important;
    width: 310px !important;
  }

  /*table td :nth-of-type(2) {
    display: none;
  }
  table td :nth-of-type(3) {
    display: none;
  }*/
}

.ModelDiv {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.settingDiv {
  display: "";
  width: 1150px;
}
.settingExp {
  width: 1069px;
}

.settingDivNone {
  display: none;
}

/*モデルメニューリスト*/
.MenuList {
  position: absolute !important;
  background: white;
  padding: 0px !important;
}

.changeModelNameDiv {
  width: 260px !important;
  margin-top: 10px !important;
}
.changeModelDiv {
  width: 260px !important;
  margin-top: 40px !important;
}

.stateTd {
  display: flex;
  flex-wrap: wrap;
}

.stateDiv {
  padding-right: 10px;
}

.modelNameCell {
  width: 226px;
}
