.link {
  text-align: end;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    cursor: pointer;
    color: blue;
  }
}

.ServiceArea {
  width: 1167px;
  /*background: red;*/
}

.contractedArea {
  /*background: green;*/
  text-align: start;
}

.title {
  padding-top: 20px;
  padding-bottom: 10px;
  font-weight: bold;
}
.comment {
  padding-bottom: 20px;
}

.cardArea {
  display: flex;
}

.card {
  background: white;
  width: 250px;
  /*height: 288px;*/
  border-radius: 20px;
  margin-right: 50px;
}

.cardTitle {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-top: 20px;
}

.cardInfo {
  padding-left: 30px;
}

.help {
  text-align: end;
  font-size: 13px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  a {
    color: blue;
    cursor: pointer;
  }
}
.line {
  border-top: solid 1px black;
  width: 200px;
  margin: auto;
}

.cardImg {
  width: 200px;
}

.img {
  width: 250px;
  height: 160px;
  cursor: pointer;
}

.contractedDiv {
  padding-left: 80px;
}
.interval {
  height: 65px;
}

.intoro {
  height: 119px;
  padding-left: 30px;
}

.planDiv_left {
  display: inline-block;
  width: 100px;
}
.planDiv_right {
  display: inline-block;
  width: 90px;
  vertical-align: top;
}

.disp_none {
  display: none;
}

.clickArea{
  cursor: pointer;
}